export const formatDate = (date?: Date | string | null) => {
  if (date === undefined || date === null) return "";
  return new Date(date).toLocaleDateString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatShortDate = (date?: Date | string | null) => {
  if (date === undefined || date === null) return "";
  return new Date(date).toLocaleDateString(undefined, {
    month: "2-digit",
    day: "2-digit",
  });
};
