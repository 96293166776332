import dayjs from "dayjs";
import type { ComponentPropsWithoutRef } from "react";
import cx from "classnames";
import { formatDate } from "@core/common/utils/formatDate";
import { useUser } from "@core/common/hooks/useUser";
import { routes } from "@core/routes/routes";
import { REDIRECT_TO } from "@core/common/utils/withServerSideAuthentication";
import type { Media } from "@core/api/common/types";
import { getImageUrl } from "@core/common/utils/getImageUrl";
import Image from "next/image";
import styles from "./EditorialNoteCard.module.css";
import { useTranslation } from "@core/i18n/useTranslation";
import { editorialNotesNamespaces } from "@domains/editorial-notes/analysesNamespaces";
import { useRouter } from "next/router";

export const AUTHENTICATION_REQUIRED_FILE = "authenticationRequiredFile";

export type EditorialNoteCardProps = {
  href?: string;
  date?: string;
  title?: string;
  content?: string;
  imageUrl?: Media | null;
  withPicture?: boolean;
} & ComponentPropsWithoutRef<"a">;

export const EditorialNoteCard = ({
  href = "",
  date,
  title = "",
  content = "",
  className,
  imageUrl,
  withPicture = false,
}: EditorialNoteCardProps) => {
  const { t } = useTranslation(editorialNotesNamespaces);

  const displayDate = dayjs(date);
  const { user } = useUser();
  const { pathname } = useRouter();

  return (
    <a
      href={
        user === undefined
          ? `${
              routes.signIn.pathname
            }?${AUTHENTICATION_REQUIRED_FILE}&${REDIRECT_TO}=${pathname}&callBackMessage=${t(
              "common:notifications.editorialNotesCallbackMessage"
            )}`
          : href
      }
      target={user === undefined ? "_self" : "_blank"}
      rel="noreferrer"
      className={cx(className, "flex flex-col bg-white rounded-lg ")}
    >
      {withPicture && (
        <div className={styles.imageContainer}>
          <Image
            lazyBoundary={"1000px"}
            className="rounded-t-lg"
            layout="fill"
            src={getImageUrl(imageUrl)}
            objectFit={"cover"}
            alt={t("editorial-notes:imageAlt")}
          />
        </div>
      )}

      <div
        className={
          "flex flex-col px-8 py-6 bg-white rounded-b-lg text-grey-400 bodySmallText font-light xl:h-full border border-grey-200 transition-shadow duration-300 hover:shadow-md"
        }
      >
        {displayDate.isValid() && <span>{formatDate(date)}</span>}
        <span className={"pt-1 heading3 font-normal"}>{title}</span>
        <p className={"pt-4 text-justify"}>{content}</p>
      </div>
    </a>
  );
};
