import type { ReactNode } from "react";
import cx from "classnames";
import styles from "./PageLayout.module.css";

type PageLayoutProps = {
  className?: string;
  children: ReactNode;
};
export const PageLayout = ({ className, children }: PageLayoutProps) => {
  return (
    <main
      className={cx(className, styles.main, "flex flex-col flex-1 mt-16 xl:mt-0 w-full xl:mx-auto")}
    >
      {children}
    </main>
  );
};
