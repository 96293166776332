import type { GetServerSideProps, WithAuthGetServerSideProps } from "@lib/next/getServerSideProps";
import Cookies from "cookies";
import { AUTH_EXPIRATION_IN_DAYS, strapi, STRAPI_COOKIE } from "@core/common/utils/strapi";
import type { StrapiUser } from "strapi-sdk-js";
import { UserRoleEnum } from "@core/api/authentication/types";
import { routes } from "@core/routes/routes";
import { getUser } from "@core/api/authentication/authentication";
import dayjs from "dayjs";

export const AUTHENTICATION_REQUIRED = "authenticationRequired";
export const REDIRECT_TO = "redirectTo";

export function withServerSideAuthentication<T>(
  authorizedRoles: UserRoleEnum[],
  getProps: WithAuthGetServerSideProps<T>
): GetServerSideProps<T> {
  return async ({ req, res, resolvedUrl, ...args }) => {
    const cookies = new Cookies(req, res);
    const jwtToken = cookies.get(STRAPI_COOKIE);
    let user: StrapiUser | null = null;
    if (jwtToken != null) {
      cookies.set(STRAPI_COOKIE, jwtToken, {
        path: "/",
        httpOnly: false,
        expires: dayjs().add(AUTH_EXPIRATION_IN_DAYS, "day").toDate(),
      });
      strapi.setToken(jwtToken);
      try {
        user = await getUser();
      } catch (error) {
        console.error(error);
      }
    }

    if (jwtToken == null || user == null || !Object.values(UserRoleEnum).includes(user.role.type)) {
      logout(cookies);
      return {
        redirect: {
          destination: `${routes.signIn.pathname}?${AUTHENTICATION_REQUIRED}&${REDIRECT_TO}=${resolvedUrl}`,
          permanent: false,
        },
      };
    } else if (
      !authorizedRoles.includes(UserRoleEnum.PRE_REGISTERED_ANALYST) &&
      user.role.type === UserRoleEnum.PRE_REGISTERED_ANALYST
    ) {
      return {
        redirect: {
          destination: routes.signUpCompleted.pathname,
          permanent: false,
        },
      };
    } else if (
      !authorizedRoles.includes(UserRoleEnum.PARTIALLY_REGISTERED_ANALYST) &&
      user.role.type === UserRoleEnum.PARTIALLY_REGISTERED_ANALYST
    ) {
      return {
        redirect: {
          destination: "/profile",
          permanent: false,
        },
      };
    } else if (!authorizedRoles.includes(user.role.type)) {
      return {
        redirect: {
          destination: routes.dashboard.pathname,
          permanent: false,
        },
      };
    }

    return await getProps({ req, res, resolvedUrl, ...args }, user);
  };
}

function logout(cookies: Cookies) {
  // That deletes the cookie
  cookies.set(STRAPI_COOKIE);
}
